<div class="spinner" *ngIf="loader; else skeleton"></div>
<app-navbar [navbarDisplay]="navbarDisplay"></app-navbar>

<div class="parent-container">
  <app-namesection [studentName]="studentName" [age]="age" class="studentName"></app-namesection>

  <h2 class="header-text">Summary Report from <br>{{ studentName }}'s Parents</h2>

  <!-- parent details section. only visible in SAM's summary report and not in parents app -->
  <div class="parent-details-container">
    <h5 style="
        margin-top: 0.4rem;
        font-family: var(--font-proxima-bold);
        color: #424242;
      ">
      Parent Details
    </h5>
    <div class="details">
      <p>Name : {{ parent_first_name }} {{ parent_last_name }}</p>
      <p>Email : {{ parent_email }}</p>
      <p>Contact No : {{ parent_contact }}</p>
    </div>
  </div>

  <!-- <div class="undefined-user-plan" *ngIf="user_plan === 'undefined' ">
    <h5>There occured an error in fetching summary report</h5>
  </div> -->

  <!-- basic profile section -->
  <div class="basicProfile" *ngIf="user_plan === 'basic' ">
    <!-- <h5 class="under-development">Summary report for basic customers is under development</h5> -->
    <div class="categories-section">
      <h5 >Basic Stats</h5>
      <div class="categories">
        <div class="category-card basicCard" (click)="goToSubcatSummary('Basic', basicAverage)">
          <h5 style="
              margin-top: 0.5rem;
              color: #8c362b;
              font-family: var(--font-proxima-bold);
            ">
            Basic
          </h5>
          <div class="cat-details">
            <div class="home-activities">
              <h6 style="font-size: 0.8rem">Customized<br />Recommendations</h6>
              <p>Total : {{basicTotal}}</p>
              <p>Done : {{basicDone}}</p>
            </div>

            <div class="average">
              <h5 style="margin: 0" *ngIf="basicAverage === 0; else default">
                Pending
              </h5>
              <ng-template #default>
                <h5>Average</h5>
              </ng-template>
              <p class="average-score">
                {{ basicAverage }}%
              </p>
              <p class="more-datails" *ngIf="basicAverage !== 0">
                more details >>
              </p>
            </div>

            <div class="chartDiv">
              <div id="piechart" class="piechart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- PREMIUM SECTION -->

  <div class="premium" *ngIf="user_plan === 'premium' " #premium>
    <!-- bargraph section  -->
    <div class="bargraph-section">
      <h5>Overall Stats</h5>
      <!-- <img src="../../../../../assets/img/bargraph.png" alt="bargraphImg"> -->
      <div id="chartdiv"></div>
    </div>

    <!-- categories section  -->
    <div class="categories-section">
      <h5 style="margin-top: 1rem; margin-bottom: 0">Category-wise Stats</h5>
      <div class="categories" *ngFor="let obj of CategoryResult">
        <div class="category-card" [ngClass]="{
            lightYellow: obj.result >= 26 && obj.result <= 65,
            lightRed: obj.result <= 25,
            lightGreen: obj.result >= 66
          }" (click)="goToSubcatSummary(obj.cat_name, obj.result)">
          <h5 style="
              margin-top: 0.5rem;
              color: #8c362b;
              font-family: var(--font-proxima-bold);
            ">
            {{ obj.cat_name }}
          </h5>
          <div class="cat-details">
            <div class="home-activities">
              <p>Customized<br />Recommendations</p>
              <p>Total : {{ obj.total }}</p>
              <p>Done : {{ obj.done }}</p>
            </div>

            <div class="average">
              <h5 style="margin: 0" *ngIf="obj.result === 0; else default">
                Pending
              </h5>
              <ng-template #default>
                <h5>Average</h5>
              </ng-template>
              <p class="average-score">{{ obj.result }}%</p>
              <p class="more-datails" *ngIf="obj.result !== 0">
                more details >>
              </p>
            </div>

            <div class="chartDiv">
              <div id="{{ obj.chart_id }}" class="piechart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<app-footer-navbar [class_id]="class_id"></app-footer-navbar>

<ng-template #skeleton>
  <app-spinner></app-spinner>
</ng-template>