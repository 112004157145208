
import { RouterLink } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from '../../../app.component';
import { UserData } from '../../interfaces/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent implements OnInit {
  public schoolName: string = '';
  private schoolLogoUrl: string = 'https://bmc-space.blr1.cdn.digitaloceanspaces.com/serviceProvider/';
  @Input() schoolLogo: string = '';

  @Input() teacherName: string = 'Mr. John Doe';
  @Input() navbarDisplay: string = "show";
  @Input() schoolNavBar : boolean = true;
  @Input() standard: string = "";
  @Input() division: string = "";
  @Input() totalStudents: number = 0;
  @Input() class_id: number = 0;
  @Input() backToDash : boolean = false;

  constructor(
    private userService: AppComponent,
    private router:Router
  ) { }
  ngOnInit() {

    // console.log("class id recieved in navbar component is : ",this.class_id);
    if (typeof window !== 'undefined' && window.localStorage) {
      const user = localStorage.getItem('user')
      if(user){
        const logo : UserData = JSON.parse(user);
        this.schoolLogo = `${this.schoolLogoUrl}${logo.school_logo}`;
        this.schoolName = logo.school_Name;
      }
    } 
  }

  goBack(teacherName:string, class_id:number){
    if(this.class_id!==0){ // neccessary for navigating back from subcat screen to classroom screen
      this.router.navigate(['/class-room', this.class_id, this.teacherName]);
    }else if(this.backToDash === true){ 
      this.router.navigate(['/dashboard']);
    }else{
      window.history.back();
    }
    
    
    
  }
}
