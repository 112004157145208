// global-object.actions.ts
import { createAction, props } from '@ngrx/store';
import { UserData } from '../../app/shared/interfaces/api';


export const setTeacherObject = createAction(
    '[classroom] Teach info',
    props<{ teacherObject: UserData }>() 
);

// Action to reset or clear the global object
export const resetGlobalObject = createAction('[Global Object] Reset Global Object');
