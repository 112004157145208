<div class=" position-sticky top-0 header w-100">
  <div *ngIf="schoolNavBar" class="topSection  w-100 d-flex justify-content-around align-items-center">
    <img src="../../../../assets/img/back_arrow.png" class="back_arrow_img" (click)="goBack(teacherName,class_id)">
    <div class="navContent" >
      
      <img [src]="schoolLogo" class="schoolLogo" alt="" srcset="">
    
    <div class=" navRight d-flex justify-content-center align-items-center flex-column">
      <p class="mt-2 mb-1 fs-4 text-wrap schoolName">{{ schoolName }}</p>
      <!-- <p class="p-0 mt-0 fs-4 text-wrap">Welcome, {{ teacherName }}</p> -->
      <div class="companyLogo d-flex justify-content-center align-items-center">
        <span>powered by</span>
        <img src="../../../../assets/img/BMC_R_Logo.png" alt="BMCLogo">
      </div>
    </div>
  </div>
  </div>


  <!-- defualt lower navbar  -->
  <div *ngIf="navbarDisplay === 'show'" class="classDetails d-flex justify-content-around align-items-center mb-2">
    <span>Welcome,<br> {{ teacherName }} </span>
    <div *ngIf="!(standard === '' || totalStudents === 0)"
      class="class d-flex justify-content-around align-items-center flex-column">
      <span>Class: {{ standard }} - {{division}}</span>
      <span>Total Students: {{totalStudents}}</span>
    </div>
  </div>

  <!-- lower navbar only for dashboard component -->
  <div *ngIf="navbarDisplay === 'dashboard' "
    class="classDetails-dashboard d-flex justify-content-around align-items-center mb-2">
    <span>Welcome, {{ teacherName }} </span>
  </div>

  <!-- lower navbar only for teacherProfile component -->
  <div *ngIf="navbarDisplay === 'teacherProfile'"
    class="classDetails-myprofile">
    <p class="your-profile">Your Profile </p>
  </div>

  <!-- lower navbar only for Invite-Confirm component -->
  <div *ngIf="navbarDisplay === 'inviteConfirm'"
    class="classDetails-myprofile d-flex justify-content-around align-items-center mb-2">
    <span>Parent information Verification</span>
  </div>

</div>