import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../../config/config';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  // private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // data$ = this.dataSubject.asObservable();

  // setData(data: any): void {
  //   this.dataSubject.next(data);
  // }
  // getStudentData() {
  //   return this.studentData;
  // }

  url :string = 'http://localhost:8080';
  token :string | null = "";

  constructor(
    private http: HttpClient,
    private config: ApplicationConfig
    ) { 
      this.url =  config.getAPIEnvironment();
  }

  
  // for getting the teacher details for the teacher profile.
  getTeacherInfoById(teacher_id: number): Observable<object> {

    if(typeof window !== 'undefined' && window.localStorage){
      this.token = localStorage.getItem('token');
    }

    const reqHeaders : HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${this.url}/teacher/getTeacherInfoById/${teacher_id}}` ,{ headers: reqHeaders });
  }

  getSummaryReportByUserId(user_id: number): Observable<object>{
    if(typeof window !== 'undefined' && window.localStorage){
      this.token = localStorage.getItem('token');
    }

    const reqHeaders : HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`https://api.boostmychild.com/v2/report/getSummaryReport/${user_id}}` ,{ headers: reqHeaders });
  }


  // for getting the parent's current plan details for the summary-report.
  checkParentPlan(user_id: number): Observable<any> {

    if(typeof window !== 'undefined' && window.localStorage){
      this.token = localStorage.getItem('token');
    }

    const reqHeaders : HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    // http://localhost:8080/v1
    return this.http.get<any>(`${this.url}/parents/checkParentPlan/${user_id}}` ,{ headers: reqHeaders });
  }

}
