import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../../services/alert/alert-service.service';
import { CommonModule } from '@angular/common';
import { MilestoneService } from '../../services/milestone.service'
@Component({
  selector: 'app-alert',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class AlertComponent implements OnInit, OnDestroy {
  message: string | null = null;
  type: 'success' | 'error' | null = null;
  header: string  = "Note";
  okButton: boolean = false;
  confirmCallback?: () => void;
  private alertSubscription!: Subscription;

  constructor(private alertService: AlertService,
    private milestoneService : MilestoneService
  ) {}

  ngOnInit() {
    this.alertSubscription = this.alertService.alert$.subscribe(alert => {
      this.message = alert.message;
      this.type = alert.type;
      this.header = alert.header;
      this.confirmCallback = alert.confirmCallback;
      setTimeout(() => this.clearAlert(), 3000);
    });
  }

  ngOnDestroy() {
    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
  }

  clearAlert() {
    this.message = null;
    this.type = null;
    this.confirmCallback = undefined;
  }

  confirm() {
    console.log("clicked confirm");
    if (this.confirmCallback) {
      this.confirmCallback();
    }
    this.clearAlert();
  }
}
