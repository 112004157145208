
<app-navbar [teacherName]="teacherName" [navbarDisplay]="navbarDisplay"></app-navbar>
<div class="parent-container" >
    
    <div class="container flex-cent flex-col">
        
        <div class="header-card">
            <h2>Dashboard</h2>
        </div>
        
        <!-- classroom option-->
        <div class="containerOptions d-flex just-between align-center"  (click)="goToClassroom()" role="presentation">
            <span>{{standard}} - {{division}}</span>
            <img class="dashboard-icons" src="../../../../assets/img/yourClassroom.png" alt="">
        </div>

        <!-- other classrooms option -->
        <div class="containerOptions d-flex just-between align-center" >
            <!-- <label>Other Classsrooms</label> -->
            <select id="select" class="other-classroom form-select form-select-lg" >
                <option selected disabled=""> Other Classsrooms</option>
                <option *ngFor="let otherClass of otherClassrooms; let i=index" [value]="otherClass.class_id">
                    {{otherClassrooms[i].standard}}-{{otherClassrooms[i].division}} 
                </option>
            </select>
            <!-- <img class="dashboard-icons" src="../../../../assets/img/otherClassrooms.svg" alt=""> -->
        </div>

        <!-- connect with parents option -->
        <div class="containerOptions d-flex just-between align-center" (click)="goToConnectWithParents()" role="presentation">
            <span>Connect with Parents</span>
            <img class="dashboard-icons" src="../../../../assets/img/parents.png" alt="">
        </div>

        <!-- overall class results on dashboard -->
    <div class="results-container flex-cent flex-col" >
        
            <span class="results-head ">Your Classsroom Average Results</span>
            <span class="date-range">Apr-10 - Oct-17, 2024</span>
            <div class="chart-content d-flex just-around align-center">
                <!-- view gray chart if no assessments taken  -->
                <div class="noResults" *ngIf=" NoResults === true">
                    <img calss="noResultsImg" src="../../../../assets/img/incomplete_category.png" alt="NOT Started">
                </div>
                <!-- view peichart as per the average of the assessments completed of whole class -->
                <div class="overallResultPie" *ngIf="NoResults === false">
                    <div class="peichart" id="chartdiv"></div>
                </div>

                <div class="average flex-cent flex-col">
                    <p>Current Average</p>
                    <h3 class="avg">{{classResult}}%</h3>
                </div>

            </div>

    </div>
  </div>  
    <app-footer-navbar [class_id]="class_id"></app-footer-navbar>
</div>