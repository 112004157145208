<div class="spinner" *ngIf="loader; else skeleton">
<app-navbar  [teacherName]="teacherName" [schoolNavBar]="true" [standard]="standard" [division]="division" [totalStudents]="totalStudents" [class_id]="class_id"></app-navbar>


<section class="d-flex align-items-center justify-content-center flex-column">
    
    <app-namesection [studentName]="studentName" [age]="age"></app-namesection>
    <div class="container">
        <span class="catName fw-bold text-center">{{cat_name}}</span>
            <div class="card-container "   *ngFor="let subcat of subCategories; index as i" >
                <div class="card subcat"  [attr.role]="'none'" (click)="subcat.completion === 'Yes' ? null : goToMilestones(subcat.subcategory_id,subcat.cat_name,'add')" [ngClass] = "{ 'completedCard': subcat.completion === 'Yes' }">
                    <span class="subcatName ">{{i+1}}. {{subcat.cat_name}}</span>
                
                    <div  *ngIf="subcat.completion === 'Yes'; else notCompleted">
                        <div class="completed ">
                            <img class="done_icon" src="../../../../assets/img/done.png" alt="completed">
                            <img class="edit_icon" src="../../../../assets/img/edit_icon.png" alt="edit" (click)="goToMilestones(subcat.subcategory_id,subcat.cat_name,'edit')" role="none">
                        </div>
                        <!-- <div class="onDate">
                            <label>{{dateConverstion(subcat.created_on)}}</label>
                        </div> -->
                    </div>
                    <ng-template #notCompleted>
                        <!-- <button class="completeNowBtn btn-warning">Complete now </button> -->
                        <div class="notCompleted me-2">
                            <img class="completeNow_icon" src="../../../../assets/img/complete_now.png" alt="completeNow">
                        </div>
                        
                    </ng-template>
                </div>  
            </div>     
    </div> 
    <!-- <button class="backButton button-position" (click)="goBack()">BACK</button> -->
</section>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>
</div>
<ng-template #skeleton>
  <app-spinner></app-spinner>
</ng-template>
