import { Component } from '@angular/core';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';

@Component({
  selector: 'app-stats-results',
  standalone: true,
  imports: [NavbarComponent,FooterNavbarComponent],
  templateUrl: './stats-results.component.html',
  styleUrl: './stats-results.component.css'
})
export class StatsResultsComponent {

  userData:any;
  teacherName:string="Mr. John Doe";
  teacher_id:number=1; 

  ngOnInit(){

    if(typeof window !=='undefined' && window.localStorage){
      this.userData = window.localStorage.getItem('user');
      this.userData=JSON.parse(this.userData); // parsing is important

      // console.log("printing userdata inside if block",this.userData);
      // console.log("teacher name is : ",this.userData.teacher_name);

      this.teacherName=this.userData.teacher_name;
      this.teacher_id=this.userData.teacher_id;
    }
  }

  goBack(){
    window.history.back();
  }

}
