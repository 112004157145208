import { Component , Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-namesection',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './namesection.component.html',
  styleUrl: './namesection.component.css'
})
export class NamesectionComponent {

  @Input() studentName:string ="Student Name" ;
  @Input() age:string ="1 ";
  @Input() milestonePage : boolean = false;
}
