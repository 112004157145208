import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';
import { MilestoneService } from '../../../shared/services/milestone.service';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { AppComponent } from '../../../app.component'

interface subCat {
  category_id: string;
  subcategory_id: number;
  cat_name: string;
  completion: string;
  created_on?: string;
}


@Component({
  selector: 'app-subcat',
  standalone: true,
  imports: [NavbarComponent, FormsModule, CommonModule, SpinnerComponent, AppComponent, FooterNavbarComponent, NamesectionComponent],
  providers: [UserService, MilestoneService,],
  templateUrl: './subcat.component.html',
  styleUrl: './subcat.component.css'
})


export class SubcatComponent {
  params: any;
  schoolName: string = 'Global International School';
  class_id: number = 1;
  cat_name: string = "Physical Development";
  student_id: number = 1;
  studentData: any;
  cat_id: number = 8;
  subCategories: subCat[] = [];
  status: string | null = "";
  dob_year: number = 2020;
  dob_date: number = 1;
  dob_month: number = 1;
  age: string = "2 yrs.";
  studentName: string = "Shyam";
  standard: string = "Nursery";
  division: string = "A";
  totalStudents: number = 20;
  teacherName: string = 'Mr. John Doe';
  loader: boolean = false;

  // for calculating piechart results
  milestones_data: any;
  peichartResult: number = 0;

  // for colorcoding of the milestones on the classroom screen
  categoryStatus: string = 'notYet';



  constructor(
    private route: ActivatedRoute,
    // private _userSerivce: UserService,
    private router: Router,
    @Inject(MilestoneService) private milestoneService: MilestoneService
  ) {
  }

  ngOnInit() {

    this.cat_id = Number(this.route.snapshot.paramMap.get('categoryId'))!;
    console.log("category id in subcat component : ",this.cat_id);
    this.cat_name = this.route.snapshot.paramMap.get('categoryName')!;
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.params = this.route.snapshot.queryParams;


    // this.studentData  = JSON.parse(this.studentData);
    //  this.class_id = this.studentData.class_id;
    //  this.student_id = this.studentData.student_id;
    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);
    this.age = this.params.age;
    this.studentName = this.params.studentName;
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.totalStudents = this.params.totalStudents;
    this.teacherName = this.params.teacherName;

    this.milestoneService.getMilestoneByCategory(this.cat_id, this.class_id, this.student_id, this.dob_month, this.dob_date, this.dob_year).subscribe({
      next: (res: any) => {
        this.subCategories = res.data.milestones;
        this.loader = true;
        console.log('Sub Categories are :', this.subCategories);

        //category color coding logic for classroom screen
        // let counter=0;
        // for(let i=0 ; i<this.subCategories.length; i++){
        //   if(this.subCategories[i].completion==='No'){
        //     counter++;
        //   }
        // }
        // console.log("subcategories not comleted count is : ",counter);
        // if(counter===0){ // not a single "No" found , meaning all subcats are completed, hence green border
        //   this.categoryStatus="Completed";
        // }else if(counter===this.subCategories.length){ // all are "No", meaning not a single subcat is touched. hence default border
        //   this.categoryStatus="notYet";
        // }else this.categoryStatus="inProgress"; // some of the subcat status are "No", hence Yellow border.


      },
      error: (error) => {
        // Handle error
        console.error('Error:', error);
      }
    });
  }

  dateConverstion(d: string): string {
    const date = new Date(d);
    return date.toLocaleDateString('en-GB');
  }

  goToMilestones(subcategory_id: number, cat_name: string , type : string) {
    this.router.navigate(['/milestone', this.class_id, this.student_id, this.cat_id, this.cat_name, subcategory_id, cat_name ,type], { queryParams: this.params });
  }
  // goBack(): void {
  //   this.router.navigate(['/class-room', this.class_id, this.teacherName]);
  //   // window.history.back();
  // }

  pieChartResults() {
    // checking data
    console.log("milestone data from pieChartResults() is : ", this.milestones_data);
  }
}