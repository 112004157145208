<app-navbar [navbarDisplay]="navbarDisplay"></app-navbar>
<div class="confirmationPage">
<div class="container">
    <div class="message">
        <p>{{childName}}'s parents have accepted the school invitation and are onboard with boostmychild mobile application</p>
    </div>

    <div class="information-card">
        <h4>Parent Details</h4>
        <div class="fields">
            <p>Name: {{parentName}}</p>
            <p NgIf="parentContactStatus">Contact No.: + {{parentContact}}</p>
            <p>Email : {{parentEmail}}</p>
        </div>
    </div>


    <div class="information-card">
        <h4>Child Details</h4>
        <div class="fields">
            <p>Name: {{childName}}</p>
            <p>Date of Birth: {{childDob}}</p>
            <p>Gender: {{childGender}}</p>
        </div>
    </div>


    <div class="buttons">
        <button class="backButton" (click)="goback()" >Cancel</button>
        <button class="backButton" (click)="confirm()">Confirm</button>
    </div>
</div>
</div>


<app-footer-navbar [class_id]="class_id"></app-footer-navbar>