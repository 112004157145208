import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class ApplicationConfig {
  apiUrl: string = environment.apiUrl;

  getAPIEnvironment(): string {
      return this.apiUrl;
  }
}
