<app-navbar [teacherName]="teacherName" [standard]="standard" [division]="division"
    [totalStudents]="totalStudents"></app-navbar>
<section class="d-flex justify-content-center align-items-center flex-column">
    <app-namesection [studentName]="studentName" [age]="age"></app-namesection>

    <div id="result" *ngIf="!content; else noGrapData" class="parent-container">

        <div class="catName">
            <h4 class="mb-2 mt-2">{{cat_name}}</h4>
            <div class="downloadBtn" (click)="screenshot()" role="presentation">
                <img src="../../../../assets/img/download_icon.png" alt="">
            </div>
        </div>

        <!-- graph-section -->
        <div id="chartdiv" class="chartdiv mt-2" style="width:90%; height: 45vh;"></div>
        <!-- <div class="mb-2 d-flex align-items-center justify-content-center flex-column">
           <span style="font-size: 0.7rem;">*Above 80% shows completed all the milestones</span>
           <span style="font-size: 0.7rem;">*Below 10% shows Niether milestone is completed</span>
        </div> -->
        
        <p class="heading_text">• Teacher's Remarks</p>
        <div class="teacher-remarks-container " *ngFor="let obj of subcatData">
            <div class="teacher-remarks-card" >
                <p class="heading_text">{{obj.cat_name}} </p>
                <!-- displaying saved remarks -->
                <p *ngIf="obj.remarks; else noRemark" class="remark-text">{{obj.remarks}}</p> 
                <ng-template #noRemark>
                    <!-- displaying default remarks // remove default remarks after 2-3 builds-->
                    <p *ngIf="obj.result<=26">{{studentName.split(' ')[0]}} is Lagging in {{obj.cat_name}} development area.</p>
                    <p *ngIf="obj.result>=27 && obj.result<=66">{{studentName.split(' ')[0]}} needs to be Encouraged in {{obj.cat_name}} development area.</p>
                    <p *ngIf="obj.result>=67">{{studentName.split(' ')[0]}} is On Track testing in {{obj.cat_name}} development area.</p>
                    
                    <!-- <p class="remark-text">No Remark</p> -->
                </ng-template>
            </div>
            <div *ngIf="checkImageState(obj.images)" class="photos-card">
                <div class="photo-preview">
                    <ul class="photos" >
                        <li class="photos" *ngFor="let image of obj.images">
                            <img class="photo" [src]="image" alt="">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="buttonDiv">
            <!-- <button class="backButton" (click)="screenshot()">Download</button> -->
            <button class="backButton" (click)="goBack()">BACK</button>
        </div>
    </div>

    <ng-template class="noGrapData d-flex" #noGrapData>
        <div class="noData d-flex align-items-center justify-content-center flex-wrap">
            <span>
                Please complete at least one milestone to see the progress
            </span>
        </div>
    </ng-template>

</section>




<app-footer-navbar [class_id]="class_id"></app-footer-navbar>