<div class="overlay" *ngIf="message"></div>
<div class="notifications" *ngIf="message">
    <h4 class="heading">{{header}}</h4>
    <p class="message">{{message}}</p>
    <div class="buttons">
        <!-- <button NgIf="okButton">ok</button> -->
            <button class="backButton" (click)="clearAlert()"> Cancel </button>
            <button class="backButton" (click)="confirm()"> Confirm </button>
    </div>
</div>
