import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  private alertSubject = new Subject<Toast>();
  toast$ = this.alertSubject.asObservable();

  showAlert(message: string, type: string,) {
    console.log("message",message);
    this.alertSubject.next({ message, type});
  }
}

interface Toast{
  type : string;
  message : string;
} 
