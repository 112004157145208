<app-navbar></app-navbar>
<div class="portfolio d-flex justify-content-center align-items-center flex-column">
<section class="topsection w-100 d-flex align-items-center justify-content-around">
    <div class="profile-photo d-flex align-items-center justify-content-center ">
        <img src="../../../../assets/img/profile.png">
    </div>
    <div class="student-name position-relative d-flex align-items-center justify-content-center flex-column fw-semibold">
        <div class="badge position-absolute top-0 end-0">
            <img  src="../../../../assets/img/badge.png" alt="badgeImg">
        </div>   
        <span class="fs-1 mt-5 text-black">Demo Child</span>
        <div class="classroom d-flex align-items-center justify-content-around w-100 mt-3 fs-5 text-white">
            <span>class: LKG-A</span>
            <span>age: 3.2 yrs</span>
        </div>
    </div>
</section>

<div class="my-portfolio mt-3">
    <span>My Portfolio</span>
</div>

<!-- about me section -->
<section class="about-me d-flex align-items-center justify-content-center flex-column text-black">
    <span>About Me</span>
    <div class="dob">
        <span>Date of Birth</span>
        <span>12 March 2019</span>
    </div>
</section>

</div>