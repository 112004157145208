import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { classRoomService } from '../../../shared/services/class-room.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { RouterLink } from '@angular/router';
import { AppComponent } from '../../../app.component';
import { MilestoneService } from '../../../shared/services/milestone.service';

@Component({
  selector: 'app-class-room',
  standalone: true,
  imports: [CommonModule, NavbarComponent, SpinnerComponent, AppComponent, FooterNavbarComponent, RouterLink],
  providers: [classRoomService, MilestoneService],
  templateUrl: './class-room.component.html',
  styleUrl: './class-room.component.css'
})
export class ClassRoomComponent implements OnInit {
  schoolName: string = 'Global International School';
  teacherName: string = 'Mr. John Doe';
  studentName: string = 'Jane Doe';
  result: any = [];
  students: any = [];
  totalStudents: number = 20;
  class_id: number = 1;
  loader: boolean = false;
  standard: string = "Classroom";
  division: string = "A";

  page = 1;
  loading = false;
  private readonly childsPerListLimit = 5;

  backToDash: boolean = true;

  // schoollogo :string;
  constructor(
    private _classRoomService: classRoomService,
    private route: ActivatedRoute,
    private router: Router,
  ) {

  }


  ngOnInit() {



    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.teacherName = String(this.route.snapshot.paramMap.get('teacher_name'));

    this.loadChildDetails();

 
    // Api call for getting the students in class room
    

  }

  loadChildDetails(){
    if (this.loading) return;
    this.loading = true;

    if (typeof window !== 'undefined' && window.localStorage) {
      this._classRoomService.getClassById(this.class_id).subscribe({
        next: (data) => {
          this.result = data;

          this.result.data.students.forEach((element :any) => {
            element.results = JSON.parse(element.results);
          });
  
          this.students.push(...this.result.data.students);
          this.page++;

          this.schoolName = this.result.data.school_name;
          this.class_id = this.result.data.class_id;
          this.standard = this.result.data.standard;
          this.division = this.result.data.division;
          this.totalStudents = this.students.length;
  
          this.loader = true;
        },
        error: (error: string) => {
          console.error('Error:', error);
        }
      });
    }
   

  }

  onScroll(): void {
    const container = document.querySelector('.studentsList') as HTMLElement;
    if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
      this.loadChildDetails();
    }
  }



  goToResult(student_id: number, studentName: string, child_age: string,): void {
    this.router.navigate(['/result', this.class_id, student_id], { queryParams: { studentName: studentName, age: child_age, schoolName: this.schoolName, totalStudents: this.totalStudents, standard: this.standard, division: this.division, teacherName: this.teacherName } });
  }

  goToSubcats(categoryId: number, cat_name: string, student_id: number, studentName: string,studentLastName:string,
    child_born_year: number, child_born_month: number, child_born_date: number, child_age: string,): void {

      if(!studentLastName){
        console.log("student last name is null: ",studentLastName);
      }else{
        studentName = studentName + " " + studentLastName; 
      }

    const studentData = {
      studentName: studentName,
      age: child_age,
      class_id: this.class_id,
      student_id: student_id,
      dob_year: child_born_year,
      dob_month: child_born_month,
      dob_date: child_born_date,
      schoolName: this.schoolName,
      totalStudents : this.totalStudents,
      standard :  this.standard,
      division :this.division,
      teacherName: this.teacherName
    };
    
    this.router.navigate(['/sub-categories', this.class_id, student_id, categoryId, cat_name], { queryParams: studentData });
  }

  calculateAge(birthYear: number, birthMonth: number, birthDate: number) {
    const currentDate = new Date();
    const birthDateObj = new Date(birthYear, birthMonth - 1, birthDate);

    const yearsDiff = currentDate.getFullYear() - birthDateObj.getFullYear();
    const monthsDiff = currentDate.getMonth() - birthDateObj.getMonth();
    const daysDiff = currentDate.getDate() - birthDateObj.getDate();

    let age = '';
    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      age = `${yearsDiff - 1}`;
      if (monthsDiff < 0) {
        age += `.${12 + monthsDiff} `;
      } else {
        age += `.${monthsDiff}`;
      }
    } else {
      age = `${yearsDiff}`;
      if (monthsDiff > 0) {
        age += `.${monthsDiff}`;
      }
    }

    age = age.replace(/\./g,'y ')
    age = `${age}m`;
    return age;
  }

}