import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { ApplicationConfig } from '../../config/config';
import { ToastService } from './alert/toast.service';
@Injectable({
  providedIn: 'root'
})
export class ResultsService {
  url: string = 'http://localhost:8080';
  token: string | null = "";
  reuestHeader: HttpHeaders = new HttpHeaders;
  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private config: ApplicationConfig
  ) {
    this.url = config.getAPIEnvironment();

  }


  setReqHeader() {
   let reqHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
      if (this.token) {
        reqHeaders = reqHeaders.set('Authorization', 'Bearer ' + this.token);
      }
    }

    return reqHeaders;
  }


  getSubcatResult(student_id: number, class_id: number, cat_id: number): Observable<object> {
 
    this.reuestHeader  = this.setReqHeader();

    const data = {
      student_id: student_id,
      class_id: class_id,
      cat_id: cat_id
    };

    return this.http.post<any>(`${this.url}/result/get_subcat_result`, data, { headers: this.reuestHeader });
  }


  // API for getting completed milstones and thier results

  getCompletedMilestones(class_id: number, student_id: number): Observable<object> {

    // if (typeof window !== 'undefined' && window.localStorage) {
    //   this.token = localStorage.getItem('token');
    // }

    // const reqHeaders: HttpHeaders = new HttpHeaders({
    //   'Authorization': 'Bearer ' + this.token,
    //   'Content-Type': 'application/json'
    // });

    this.reuestHeader  = this.setReqHeader();

    return this.http.get<any>(`${this.url}/result/get_completed_milestones/${class_id}/${student_id}`, { headers:  this.reuestHeader }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          if (typeof window !== 'undefined') {
            this.toastService.showAlert("Complete one milestone to see results", 'Note');
            window.history.back();
          }
          return of([]);
        } else {
          return of([]);
        }
      })
    );
  }

  // for piechart on the dashboard
  getClassOverallResult(class_id: number): Observable<object> {

    // if (typeof window !== 'undefined' && window.localStorage) {
    //   this.token = localStorage.getItem('token');
    // }

    // const reqHeaders: HttpHeaders = new HttpHeaders({
    //   'Authorization': 'Bearer ' + this.token,
    //   'Content-Type': 'application/json'
    // });

    this.reuestHeader  = this.setReqHeader();


    return this.http.get<any>(`${this.url}/result/get_class_overall_result/${class_id}}`, { headers:  this.reuestHeader });
  }

}
