<div class="spinner" *ngIf="loader; else skeleton">
    <app-navbar [teacherName]="teacherName" [navbarDisplay] = "navbarDisplay"></app-navbar>

    <div class="cwp-page">
        <h2 class="text-center parent-connect-heading">Parents Connect</h2>

        <section class="container">
            <div *ngFor="let obj of child_parent_details; let i = index"
                [ngClass]="{'card-light-yellow':i%2===0 , 'card-dark-yellow': i%2!==0 }">
                <div class="parent-card">
                    <div class="logoName">
                        <img src="../../../../assets/img/profile.png" alt="childPic">
                        <div class="names">
                            <span class="childName">{{obj.child_name}}</span>
                            <span class="parentName">{{obj.parent_name}} {{obj.parent_last_name}}</span>
                        </div>
                    </div>

                    <button class="invite-button" (click)="onInviteClick(obj.child_id, obj.school_connect_status)"
                        [ngClass]="getButtonClass(obj.school_connect_status)"
                        [disabled]="isInviteDisabled(obj.school_connect_status)">
                        {{ getButtonText(obj.school_connect_status) }}
                    </button>

                </div>
            </div>
            
        </section>
    </div>
    <app-footer-navbar [class_id]="class_id"></app-footer-navbar>
</div>
<ng-template #skeleton>
    <app-spinner></app-spinner>
</ng-template>