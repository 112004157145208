import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MilestoneService } from '../../../shared/services/milestone.service';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { buffer } from 'stream/consumers';
import { image } from 'html2canvas/dist/types/css/types/image';
import { Url } from 'url';
import { pluck } from 'rxjs';

@Component({
  selector: 'app-camera',
  standalone: true,
  imports: [NavbarComponent, CommonModule , SpinnerComponent],
  providers: [MilestoneService],
  templateUrl: './camera.component.html',
  styleUrl: './camera.component.css',
})
export class CameraComponent implements OnInit, OnDestroy {
  navbarDisplay: string = 'none';
  photoClicked: boolean = false;
  
  @ViewChild('video', { static: false })
  public video!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas', { static: false })
  public canvas!: ElementRef<HTMLCanvasElement>;

  params : any; 
  loader: boolean = true;

  photoPreview: Array<string> = []; // just for previewing on user's screen.
  captures: Array<Blob> = []; // for storing the converted photos from raw-format to blob, and hitting the api for upload.

  mediaDevices: MediaDevices = navigator.mediaDevices;
  cameraPermissionGranted: boolean = false;
  private mediaStream: MediaStream | null = null;
  photoCounter: number = 0;
  useRearCamera: boolean = true;
  rawImage: string = '';
  photoActions: Array<boolean> = [false];

  
  student_id: number = 0;
  cat_id: number = 0;
  subcat_id: number = 0;
  catName: string = '';
  subcat_name: string = '';
  class_id: number = 0;
  type : string = '';

  milestoneState: string = "";
  studentName: string = '';
  teacherName: string = '';
  totalStudents: number = 0;
  standard: any;
  division: any;
  dob_date: number = 0;
  dob_month: number = 0;
  dob_year: number = 0;
  age : string = '';
  score : number = 0;
  remark : string = '';
  uploadedPhotoCount: number = 0;
  photoLimit : number = 0;

  returnedFromCamera: boolean = true;
  photoUploaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(MilestoneService) private milestoneService: MilestoneService
  ) {

    this.params = this.route.snapshot.queryParams;
    console.log("params recieved are : ",this.params);  

    this.student_id = Number(this.params.student_id);
    this.cat_id = Number(this.params.catId);
    this.subcat_id = Number(this.params.subcatId);
    this.subcat_name = this.params.subcatName;
    this.catName = this.params.catName;
    this.subcat_name = this.subcat_name.replace(/ /g, '_'); // removing space
    this.studentName = this.params.studentName;
    this.studentName = this.studentName.replace(/ /g, '_'); // removing space
    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);
    this.class_id = Number(this.params.classId);
    this.age = this.params.age;
    this.type = this.params.type;
    this.milestoneState=this.params.milestoneState;
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.teacherName = this.params.teacherName;
    this.totalStudents = this.params.totalStudents;
    this.returnedFromCamera = this.params.returnedFromCamera;
    this.score = this.params.score;
    this.remark = this.params.remark;
    this.uploadedPhotoCount = Number(this.params.uploadedPhotoCount);
    this.photoLimit = 5 - Number(this.params.uploadedPhotoCount); // limit of 5 photos
    

// testing
    // console.log('recieved student_id is ', this.student_id);
    // console.log('recieved cat_id is ', this.cat_id);
    // console.log('recieved subcat_id is ', this.subcat_id);
    // console.log('reieved student name is : ', this.studentName);
    // console.log("recieved subcat name is : ",this.subcat_name);
    // console.log("dob_date : ",this.dob_date);
    // console.log("dob_month: ", this.dob_month);
    // console.log("dob_year: ",this.dob_year);
    // console.log("milestone state in camera component: ",this.milestoneState);
    // console.log("class-id recieved is : ",this.class_id);
    // console.log("type recieved is : ", this.type);
    console.log("totalStudents : ",this.totalStudents);
  }

  public ngOnInit() {
    navigator.permissions
      .query({ name: 'camera' as PermissionName })
      .then(permissionStatus => {
        if (permissionStatus.state === 'granted') {
          // camera permission already granted while last visit
          this.cameraPermissionGranted = true;
          this.startCamera(true);
        } else {
          // requesting the user to give camera permission
          this.mediaDevices
            .getUserMedia({
              video: {
                facingMode: this.useRearCamera
                  ? { exact: 'environment' }
                  : 'user',
              },
              audio: false,
            })
            .then(stream => {
              this.cameraPermissionGranted = true;
              this.mediaStream = stream;
              const videoElement = document.querySelector('video');
              if (videoElement) {
                videoElement.srcObject = stream;
              }
            })
            .catch(error => {
              if (error.name === 'NotAllowedError') {
                // if user denied camera permission
                console.log('Camera permission was denied by the user.');
              } else {
                console.error('Error accessing camera: ', error);
              }
            });
        }

        // Listen for changes in the permission state
        permissionStatus.onchange = () => {
          this.cameraPermissionGranted = permissionStatus.state === 'granted';
          location.reload();
        };
      })
      .catch(error => {
        console.error('Error checking camera permission: ', error);
      });
  }

  startCamera(useRearCamera: boolean) {
    const constraints = {
      video: {
        facingMode: useRearCamera ? { exact: 'environment' } : 'user', // here environment is used for rear camera , and user is for front camera. meaning camera will by default open rear camera. if user switches then only front camera will be accessed.
      },
      audio: false,
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        // Handle the stream
        this.mediaStream = stream;
        const videoElement = this.video.nativeElement;
        videoElement.srcObject = stream;
        videoElement.play();
      })
      .catch(error => {
        if (error.name === 'NotAllowedError') {
          console.error(
            'User has not given permission to access the camera',
            error
          );
        } else {
          console.error('Error accessing media devices.', error);
        }
      });
  }

  // Method to toggle between front and rear cameras
  switchCamera() {
    // Stop all current tracks before switching
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(track => track.stop());
    }

    // Toggle between rear and front camera
    this.useRearCamera = !this.useRearCamera;
    this.startCamera(this.useRearCamera);
  }

  public capture() {
    if (!this.cameraPermissionGranted) {
      // camera permission not granted
      console.log('camera permission not given !!!!!');
      alert('please provide camera access');
      // location.reload();
    } else {
      // camera permission granted
      if (this.photoCounter <= this.photoLimit-1) {
        this.photoClicked = true;

        const canvasElement = this.canvas.nativeElement;
        const videoElement = this.video.nativeElement;

        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;

        const context = canvasElement.getContext('2d');

        if (context) {
          context.drawImage(
            videoElement,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );
          const imageDataUrl = canvasElement.toDataURL('image/jpeg', 0.75); // JPEG format with 75% quality
          console.log('type of imageDataUrl is : ', typeof imageDataUrl);
          this.photoPreview.push(imageDataUrl);
        }

        console.log('images clicked: ', this.photoCounter);
        this.photoCounter++;
      } else {
        if(Number(this.params.photoCount)=== 0 ){
          alert(`Up to 5 images can be uploaded.`);
        }else{
          alert(`Up to 5 images can be uploaded. Of which ${this.uploadedPhotoCount} images are already uploaded previously`);
        }
      }
    }
  }


  // Turn off the camera when the component is destroyed
  ngOnDestroy() {
    console.log('ngOnDestroy called *******');

    // turning off the camera.
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(track => track.stop());
      this.mediaStream = null; // Clear the reference
      console.log('Camera has been turned off.');
    } else {
      console.log('No media stream to stop.');
    }
  }

  actions(photo: string, index: number) {
    // console.log('show actions called $$$$');
    // console.log('index recieved is : ', index);
    for (let i = 0; i < this.photoPreview.length; i++) {
      if (i === index) {
        this.photoActions[index] = true;
        // console.log('photo actions array in actions ', this.photoActions);
      }
    }
  }

  disableActions() {
    this.photoActions = [false];
    // console.log('photo actions array in disable ', this.photoActions);
  }

  public downloadImage(imageDataUrl: string) {
    if (!confirm('Download this image?')) {
      return;
    } else {
      const isIOS = /iPad|iPhone|mac|iPod/.test(navigator.userAgent);

      if (isIOS) {
        // For iOS, open in a new tab
        const newTab = window.open('', '_blank');
        if (newTab) {
          newTab.document.body.innerHTML = `<img src="${imageDataUrl}" alt="Captured Image" style="width:auto; height:80vh;">`;
        }
      } else {
        // For other devices, use Blob to trigger download
        const byteString = atob(imageDataUrl.split(',')[1]);
        const mimeString = imageDataUrl
          .split(',')[0]
          .split(':')[1]
          .split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: mimeString });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `capture-${new Date().toISOString()}.jpg`;
        link.click();
      }
    }
  }

  deletePhoto(index: number) {
    const deletedPhoto = this.photoPreview.splice(index, 1); // splice works like pop(), used to remove any element/s from array at any location/s. (here 2nd argument " 1 " denotes the no. of elements to be removed)
    console.log('deleted photo is : ', deletedPhoto);
    console.log('new array is : ', this.photoPreview);
    this.disableActions(); // disabling the actions of the photo that is deleted.
    this.photoCounter--; // reducing the count of photos as one photo gets deleted.
  }

  // function to convert base64 i.e. raw format images to blob images.
  base64ToBlob(base64: string): Blob {
    const base64Data = base64.split(',')[1];

    // Decode the base64 data to binary
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'image/jpeg' });
  }

  saveImages() {
    // converting raw images to blob format.
    const blobImages: Blob[] = this.photoPreview.map(this.base64ToBlob);
    let presignedUrls: Array<{
      filename: string;
      path: string;
      presignedUrl: string;
    }> = [];
    // console.log('Blob converted array is : ', blobImages);

    //creating filenames for images
    let count: number = 1;
    let imageFileNames: Array<string> = [];
    let fileName: string = '';
    for (let i = 1; i <= this.photoPreview.length; i++) {
      // filenames are given as studentName_subcatName_NumberOfImage
      fileName = `${this.studentName}_${this.subcat_name}_${count}.jpeg`;
      imageFileNames.push(fileName);
      count++;
    }
    console.log('fileNames are : ', imageFileNames);

    this.loader = false;

    // getting the presigned urls for all images, and uploading images to their respective presigned url.

    this.milestoneService
      .getPresignedUrl(
        imageFileNames,
        this.cat_id,
        this.subcat_id,
        this.student_id,
        this.dob_date,
        this.dob_month,
        this.dob_year
      )
      .subscribe({
        next: async (data: any) => {
          presignedUrls = data.data;
          console.log(
            'generated presigned urls for all images: ',
            presignedUrls
          );

          // finally uploading the blob format images on its respective presigned url
          for (let i = 0; i < presignedUrls.length; i++) {
            try {
              const response = await fetch(presignedUrls[i].presignedUrl, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'image/jpeg',
                  "x-amz-acl": "public-read",
                },
                body: blobImages[i],
              });

              if (response.ok) {
                console.log('Image uploaded successfully!');
                this.photoUploaded = true;

                imageFileNames = []; // emptying imageFileNames array to store imagePaths
                console.log('filenames array after emptying', imageFileNames);
              } else {
                alert('Image upload failed:');
              }
            } catch (error) {
              alert('Something went wrong');
            }
          }

          for (let obj of presignedUrls) {
            let imagePath: string = obj.path;
            console.log('image path is : ', imagePath);
            imageFileNames.push(imagePath);
          }

          console.log(
            'Updated imageFileNames array with paths',
            imageFileNames
          );

          //testing
          console.log('student_id : ', this.student_id);
          console.log('cat_id : ', this.cat_id);
          console.log('subcat_id : ', this.subcat_id);
          console.log('dob_date : ', this.dob_date);
          console.log('dob_month : ', this.dob_month);
          console.log('dob_year : ', this.dob_year);

          // inserting imagePaths in database.
          this.milestoneService
            .insertImagePath(
              this.student_id,
              this.cat_id,
              this.subcat_id,
              imageFileNames, // here we're reusing the 'imageFileNames' array to send imagePaths to save some space.
              this.dob_date,
              this.dob_month,
              this.dob_year
            )
            .subscribe({
              next: async (data: any) => {
                console.log('data from insertImagePath api', data);

                this.backToMilestone();
              },
            }); // insertImagePath api end
        },
      }); // presignedUrl api end
  }

  cancel() {
    this.rawImage = '';
    this.photoPreview = [];
    this.photoClicked = false; 
    this.backToMilestone();
  }

  backToMilestone(){
    this.returnedFromCamera = true;

    this.subcat_name = this.subcat_name.replace(/_/g, ' '); // adding space
    this.studentName = this.studentName.replace(/_/g, ' '); 

    console.log("photoUploaded in camera component",this.photoUploaded);
    const backToMilestone: object= {
      standard: this.standard,
      division: this.division,
      student_id : this.student_id,
      studentName: this.studentName,
      teacherName: this.teacherName,
      totalStudents: this.totalStudents,
      age : this.age,
      dob_date: this.dob_date,
      dob_month: this.dob_month,
      dob_year: this.dob_year,
      milestoneState: this.milestoneState,
      returnedFromCamera: this.returnedFromCamera,
      score : this.score,
      remark: this.remark,
      photoUploaded: this.photoUploaded
    }

    this.router.navigate(['/milestone',this.class_id,this.student_id, this.cat_id, this.catName,  this.subcat_id, this.subcat_name, this.type], {queryParams: backToMilestone});
  }
}
