import { Component } from '@angular/core';

@Component({
  selector: 'app-parent-share',
  standalone: true,
  imports: [],
  templateUrl: './parent-share.component.html',
  styleUrl: './parent-share.component.css'
})
export class ParentShareComponent {
     
}
