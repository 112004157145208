/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MilestoneService } from '../../../shared/services/milestone.service';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { AlertService } from '../../../shared/services/alert/alert-service.service';
import { AlertComponent } from '../../../shared/component/alert-box/alert-box.component';
import { ToastService } from '../../../shared/services/alert/toast.service';
import { SpeechRecognitionService } from '../../../shared/services/speech-recognition.service';
import { fromEvent } from 'rxjs';
import { object } from '@amcharts/amcharts5';

@Component({
  selector: 'app-milestone',
  standalone: true,
  imports: [
    NavbarComponent,
    AlertComponent,
    CommonModule,
    FormsModule,
    NgbProgressbarModule,
    SpinnerComponent,
    FooterNavbarComponent,
    NamesectionComponent,
  ],
  providers: [MilestoneService],
  templateUrl: './milestone.component.html',
  styleUrl: './milestone.component.css',
})
export class MilestoneComponent implements OnInit {
  @ViewChild('micButton') micButton!: ElementRef;
  catId: number = 8;
  catName: string = 'Physical Development';
  subCatId: number = 7;
  class_id: number = 1;
  student_id: number = 220;
  params: any = {};
  schoolName: string = 'Global International School';
  teacherName: string = 'Mr. John Doe';
  studentName: string = 'Shyam';
  none: string = 'none';
  age: string = '2.3 yrs.';
  subCat: string = 'Mathematics';
  progressValue: number = 50;
  rangeValue: number = 0;
  arrowLeft: number = 0; // milestone score is stored in this variable
  milestones: any[] = [];
  savedMilestone: any;
  defaultImage = '../../../../assets/img/milestone_defaultImg.png';
  selectedMilestones: any = {
    YES: [],
    SOMETIMES: [],
    NO: [],
    totalMilestones: 2,
  };
  userData: any;
  school_id: number = 1;
  age_group: number = 11;
  studentData: any;
  dob_date: any;
  dob_month: any;
  dob_year: any;
  subcat_name: string = 'Mathematics';
  standard: any;
  division: any;
  totalStudents: any;
  loader: boolean = false;
  score: number = 0;
  url: string =
    'https://bmc-space.blr1.cdn.digitaloceanspaces.com/uploads/question/';
  toast: boolean = false;
  milestoneToast: boolean = false;
  type: string = 'add';
  record_id: number = 1;
  remark: string = `Teacher's Remark`;
  errorMessage: string = '';
  returnedFromCamera: string = 'false';
  milestoneState: string = '';
  results: any;
  isListening: boolean = false;
  photoCount: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private toastService: ToastService,
    private speechRecognitionService: SpeechRecognitionService,
    @Inject(MilestoneService) private milestoneService: MilestoneService
  ) {}

  ngOnInit() {
    this.params = this.route.snapshot.queryParams;
    // testing
    console.log('queryParams recieved : ', this.params);

    this.catId = Number(this.route.snapshot.paramMap.get('categoryId'))!;
    this.subCatId = Number(this.route.snapshot.paramMap.get('sub_id'))!;
    this.subcat_name = this.route.snapshot.paramMap.get('subcat_name')!;
    this.catName = this.route.snapshot.paramMap.get('cat_name')!;
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.type = this.route.snapshot.paramMap.get('type')!;
    console.log('type recieved is : ', this.type);

    this.standard = this.params.standard;
    this.division = this.params.division;
    this.totalStudents = this.params.totalStudents;
    this.teacherName = this.params.teacherName;

    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);
    this.age = this.params.age;
    this.studentName = this.params.studentName;
    this.milestoneState = this.params.milestoneState;
    this.returnedFromCamera = this.params.returnedFromCamera;

    if (typeof window !== 'undefined' && window.localStorage) {
      this.milestoneService
        .getMilestoneBySubCategory(
          this.catId,
          this.subCatId,
          this.class_id,
          this.student_id,
          this.dob_month,
          this.dob_date,
          this.dob_year
        )
        .subscribe({
          next: (res: any) => {
            /**
             * this api condion used when user wants to update aleady saved milestone
             * we get the saved milestone
             */

            if (this.returnedFromCamera === 'true') {
              // returned from camera milestone screen

              console.log(
                'returned from camera called ^^^^^^^^^^^^^^^^^^^^^^^^'
              );
              this.selectedMilestones = JSON.parse(this.milestoneState);
              console.log(
                'inside returnedFromCamera milestone state object : ',
                this.selectedMilestones
              );

              this.milestones = res.data.map(
                (milestone: {
                  red: boolean;
                  green: boolean;
                  yellow: boolean;
                  clicks: number;
                  milestone_id: any;
                }) => {
                  // Initialize properties
                  milestone.red = false;
                  milestone.green = false;
                  milestone.yellow = false;
                  milestone.clicks = 0;
                  // console.log("milestone_status", milestone_status);
                  // console.log("YEs",milestone_status.YES);
                  // console.log("milestone id",milestone.milestone_id)

                  console.log(
                    'milestone_status.YES.includes(milestone.milestone_id)',
                    this.selectedMilestones.YES.includes(milestone.milestone_id)
                  );
                  // Update properties based on milestone status
                  if (
                    this.selectedMilestones.YES.includes(milestone.milestone_id)
                  ) {
                    // console.log("yes");
                    milestone.green = true;
                    milestone.clicks = 1;
                  } else if (
                    this.selectedMilestones.NO.includes(milestone.milestone_id)
                  ) {
                    // console.log("no");

                    milestone.red = true;
                    milestone.clicks = 2;
                  } else if (
                    this.selectedMilestones.SOMETIMES.includes(
                      milestone.milestone_id
                    )
                  ) {
                    // console.log("sometimes");
                    milestone.yellow = true;
                    milestone.clicks = 3;
                  }

                  return milestone;
                }
              );

              this.selectedMilestones.totalMilestones = this.milestones.length;
              this.loader = true;

              this.score = Number(this.params.score);
              this.remark = this.params.remark;
              console.log(
                'photoUploaded from params: ',
                this.params.photoUploaded
              );
              let photoUploaded = this.params.photoUploaded;

              if (
                this.returnedFromCamera === 'true' &&
                photoUploaded === 'true'
              ) {
                this.toastService.showAlert(
                  'Photos Uploaded Sucessfully',
                  'Note'
                );
              }
              if (
                this.returnedFromCamera === 'true' &&
                photoUploaded === 'false'
              ) {
                this.toastService.showAlert('No Photos were Uploaded', 'Note');
              }

              // getting the uploaded images count
              this.getUploadedImagesCount();
            } else {
              if (this.type === 'edit') {
                // edit milestone screen
                console.log(
                  'edit milestone screen called $$$$$$$$$$$$$$$$$$$$$$$'
                );
                this.milestones = res.data;
                console.log('main milestones', this.milestones);
                this.getSavedMilestones(
                  this.subCatId,
                  this.catId,
                  this.class_id,
                  this.student_id
                );

                console.log('subcat id : ', this.subCatId);
                console.log('cat id : ', this.catId);
                console.log('class id : ', this.class_id);
                console.log('student id : ', this.student_id);
              } else {
                // brand new milestone screen
                console.log(
                  ' new milestone called $$$$$$$$$$$$$$$$$$$$$$$$$$$'
                );
                this.milestones = res.data.map((milestone: any) => ({
                  ...milestone,
                  red: false,
                  green: false,
                  yellow: false,
                  clicks: 0,
                }));
              }
            }

            this.selectedMilestones.totalMilestones = this.milestones.length;
            this.loader = true;
          },
          error: (error: any) => {
            console.error('Error fetching milestones:', error);
          },
        });
    }
  }

  pressed() {
    const micButtonEl = this.micButton.nativeElement;

    this.checkMicrophonePermission()
    .then((permissionGranted) => {
      if (permissionGranted) {
        // If permission is granted, toggle recognition
        this.toggleRecognition(micButtonEl);
      } else {
        console.error('Microphone access denied.');
      }
    })
    .catch((error) => {
      console.error('Error checking microphone permission:', error);
    });

    // this.toggleRecognition(micButtonEl);
  }

  async checkMicrophonePermission(): Promise<boolean> {
    return navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        stream.getTracks().forEach(track => track.stop());
        return true; 
      })
      .catch((err) => {
        return false;
      });
  }

  toggleRecognition(micButtonEl: any) {
    if (!this.isListening) {
      console.log('started');
      this.speechRecognitionService.startListening();

      this.isListening = true;
      micButtonEl.classList.add('shrink-mic');
      micButtonEl.classList.add('rotate-circle');
    } else {
      const textArea = document.getElementById('remark') as HTMLTextAreaElement;

      if (
        this.speechRecognitionService.getTranscript() &&
        this.speechRecognitionService.getTranscript() !== ''
      ) {
        textArea.value = this.speechRecognitionService.getTranscript();
      }
      console.log('stoped');

      this.speechRecognitionService.stopListening();
      this.isListening = false;

      micButtonEl.classList.remove('shrink-mic');
      micButtonEl.classList.remove('rotate-circle');
    }
  }

  // ngAfterViewInit() {
  //   if (this.returnedFromCamera) {
  //     let textArea = document.getElementById('remark') as HTMLTextAreaElement;
  //     textArea.value = this.remark;
  //   }
  // }

  /**
   * This fucntion is used to get the value of the input field
   * @param event
   */
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.score = parseInt(input.value);
    this.addDefaultRemark(this.score);
  }

  handleImageKeydown(event: MouseEvent, image: any) {
    if (!image.clicks) {
      image.clicks = 0;
    }
    // console.log("clicked", image.clicks);

    // green color on one click means yes
    // yellow color on two click means sometimes
    // red color on three click means no

    if (image.clicks === 0) {
      image.green = true;
      image.red = false;
      image.yellow = false;
      this.selectedMilestones.YES.push(image.milestone_id);
      image.clicks = 1;
    } else if (image.clicks === 1) {
      image.green = false;
      image.yellow = true;
      image.red = false;
      this.selectedMilestones.YES.pop(image.milestone_id);
      this.selectedMilestones.SOMETIMES.push(image.milestone_id);
      image.clicks = 2;
    } else if (image.clicks === 2) {
      image.green = false;
      image.yellow = false;
      image.red = true;
      this.selectedMilestones.SOMETIMES.pop(image.milestone_id);
      this.selectedMilestones.NO.push(image.milestone_id);
      image.clicks = 3;
    } else {
      image.red = false;
      image.green = false;
      image.yellow = false;
      this.selectedMilestones.NO.pop(image.milestone_id);
      image.clicks = 0;
    }

    const yesLength = this.selectedMilestones.YES.length;
    const sometimesLength = this.selectedMilestones.SOMETIMES.length;
    const milestonesLength = this.milestones.length;
    const noLength = this.selectedMilestones.NO.length;

    let total = 0;

    if (yesLength === milestonesLength) {
      total = 90;
    } else {
      console.log('noLength', noLength);
      console.log('yesLength', yesLength);
      console.log('noLength', sometimesLength);
      total =
        ((yesLength * 9 + noLength * 2 + sometimesLength * 4) * 100) /
        ((yesLength + sometimesLength + noLength) * 10);
      if (isNaN(total)) {
        total = 0;
      }
    }

    this.score = Math.round(total);
    console.log('printing runtime score: ', this.score);

    this.addDefaultRemark(this.score);
  }

  getResult() {
    console.log(this.results);
  }

  closeAlert(): void {
    this.milestoneToast = false;
  }

  getUploadedImagesCount() {
    this.milestoneService
      .fetchImages(
        this.student_id,
        this.catId,
        this.subCatId,
        this.dob_date,
        this.dob_month,
        this.dob_year
      )
      .subscribe({
        next: (data: any) => {
          let images = JSON.parse(data.data[0].images);
          this.photoCount = images.length;
          console.log("student id : ",this.student_id);
          console.log("cat id : ",this.catId);
          console.log("subcat id : ",this.subCatId);
          console.log("dob_date id : ",this.dob_date);
          console.log("dob_month id : ",this.dob_month);
          console.log("dob_year id : ",this.dob_year);
          console.log(
            'fetched images are : ',
            images,
            ' and photocount is : ',
            this.photoCount
          );
        },
        error: (error: any) => {
          console.error('Error in fetching images:', error);
        },
      });
  }

  goToCamera() {
    console.log('user pressed camera button !');

    


    console.log("uploaded photCount is : ",this.photoCount);
    if (this.photoCount >= 0 && this.photoCount < 5) {
      let milestoneState: string = JSON.stringify(this.selectedMilestones);

      // creating one object for sending it to camera component and again getting it back from there. so we can maintain the milestones-component state.
      const cameraData: object = {
        studentName: this.studentName,
        student_id: this.student_id,
        catId: this.catId,
        catName: this.catName,
        subcatId: this.subCatId,
        subcatName: this.subcat_name,
        dob_date: this.dob_date,
        dob_month: this.dob_month,
        dob_year: this.dob_year,
        classId: this.class_id,
        age: this.age,
        standard: this.standard,
        division: this.division,
        teacherName: this.teacherName,
        totalStudents: this.totalStudents,
        type: this.type,
        milestoneState: milestoneState,
        returnedFromCamera: false,
        score: this.score,
        remark: this.remark,
        uploadedPhotoCount: this.photoCount,
      };

      this.router.navigate(['/camera'], { queryParams: cameraData });
    } else {
      this.toastService.showAlert('You have already uploaded 5 images', 'Note');
    }
  }

  saveMilestone() {
    const textArea = document.getElementById('remark') as HTMLTextAreaElement;
    this.remark = textArea.value;
    console.log('remark by teacher is : ', this.remark);

    if (
      this.selectedMilestones.YES.length === 0 &&
      this.selectedMilestones.NO.length === 0 &&
      this.selectedMilestones.SOMETIMES.length === 0
    ) {
      this.toastService.showAlert(
        'To save the assessment, you must assess at least one milestone',
        'Note'
      );
      return;
    }
    if (this.selectedMilestones.length !== 0) {
      if (this.type === 'edit') {
        // console.log("edit service");

        this.alertService.showAlert(
          'Note',
          `You have selected ${this.selectedMilestones.YES.length + this.selectedMilestones.NO.length + this.selectedMilestones.SOMETIMES.length} out of ${this.milestones.length} milestones. `,
          'success',
          this.updateSavedMilestone.bind(this)
        );
      } else {
        // console.log("alert service");
        this.alertService.showAlert(
          'Note',
          `You have selected ${this.selectedMilestones.YES.length + this.selectedMilestones.NO.length + this.selectedMilestones.SOMETIMES.length} out of ${this.milestones.length} milestones. `,
          'success',
          this.savedMilestoneConfirmation.bind(this)
        );
      }
    }
  }

  savedMilestoneConfirmation() {
    const milestone_ids = JSON.stringify(this.selectedMilestones);
    this.milestoneService
      .saveMilestone(
        this.catId,
        this.subCatId,
        this.class_id,
        this.student_id,
        this.school_id,
        milestone_ids,
        this.score,
        this.remark,
        this.dob_month,
        this.dob_date,
        this.dob_year
      )
      .subscribe({
        next: (res: any) => {
          this.router.navigate(
            [
              '/sub-categories',
              this.class_id,
              this.student_id,
              this.catId,
              this.catName,
            ],
            { queryParams: this.params }
          );
        },
        error: (error: any) => {
          console.error('Error saving milestones:', error);
        },
      });
  }

  updateSavedMilestone() {
    this.milestoneService
      .updateSavedMilestone(
        JSON.stringify(this.selectedMilestones),
        this.score,
        this.record_id,
        this.remark
      )
      .subscribe({
        next: (data: any) => {
          console.log('updated remark is : ', this.remark);
          this.router.navigate(
            [
              '/sub-categories',
              this.class_id,
              this.student_id,
              this.catId,
              this.catName,
            ],
            { queryParams: this.params }
          );

          // showing the toast only when the api is fired.
          // this.milestoneUpdatedToast = true;
          // setTimeout(()=>{
          //   window.history.back();
          // },1500);
        },
        error: (error: any) => {
          console.error('Error fetching milestones:', error);
        },
      });
  }

  getSavedMilestones(
    subcat_id: number,
    cat_id: number,
    class_id: number,
    student_id: number
  ) {
    // to get the already saved milestone results so as to edit them.
    let milestone_status: any;
    this.milestoneService
      .getSavedMilestone(subcat_id, cat_id, class_id, student_id)
      .subscribe({
        next: (data: any) => {
          console.log('get saved milestone data: ', data);
          this.savedMilestone = data.data[0];
          console.log('this.savedMilestone', this.savedMilestone);
          milestone_status = JSON.parse(data.data[0].milestone_ids);
          console.log(
            'milestone_status parced from database:',
            milestone_status
          );

          this.score = this.savedMilestone.result;
          this.record_id = this.savedMilestone.record_id;
          this.remark = data.data[0].remarks;
          // document.getElementById('remark').value = this.remark;

          this.selectedMilestones.YES = milestone_status.YES;
          this.selectedMilestones.NO = milestone_status.NO;
          this.selectedMilestones.SOMETIMES = milestone_status.SOMETIMES;

          // getting the saved remark
          const textArea = document.getElementById(
            'remark'
          ) as HTMLTextAreaElement;
          console.log('saved remark is : ', data.data[0].remarks);

          if (data.data[0].remarks) {
            // saved remark
            textArea.value = data.data[0].remarks;
          } else {
            this.addDefaultRemark(this.score);
          }

          this.milestones = this.milestones.map(milestone => {
            // Initialize properties
            milestone.red = false;
            milestone.green = false;
            milestone.yellow = false;
            milestone.clicks = 0;
            // console.log("milestone_status", milestone_status);
            // console.log("YEs",milestone_status.YES);
            // console.log("milestone id",milestone.milestone_id)

            console.log(
              'milestone_status.YES.includes(milestone.milestone_id)',
              milestone_status.YES.includes(milestone.milestone_id)
            );
            // Update properties based on milestone status
            if (milestone_status.YES.includes(milestone.milestone_id)) {
              // console.log("yes");
              milestone.green = true;
              milestone.clicks = 1;
            } else if (milestone_status.NO.includes(milestone.milestone_id)) {
              // console.log("no");

              milestone.red = true;
              milestone.clicks = 2;
            } else if (
              milestone_status.SOMETIMES.includes(milestone.milestone_id)
            ) {
              // console.log("sometimes");
              milestone.yellow = true;
              milestone.clicks = 3;
            }

            return milestone;
          });

          this.selectedMilestones.totalMilestones = this.milestones.length;
          this.loader = true;
        },
        error: (error: any) => {
          console.error('Error fetching milestones:', error);
        },
      });
  }

  addDefaultRemark(score: number) {
    const textArea = document.getElementById('remark') as HTMLTextAreaElement;

    if (this.score === 0) {
      textArea.value = '';
      this.remark = textArea.value;
      console.log('default remark by teacher is : ', this.remark);
    } else if (this.score <= 26) {
      textArea.value = `${this.studentName.split(' ')[0]} is Lagging in ${this.catName} development area.`;
      this.remark = textArea.value;
      console.log('default remark by teacher is : ', this.remark);
    } else if (this.score >= 27 && this.score <= 66) {
      textArea.value = `${this.studentName.split(' ')[0]} needs to be Encouraged in ${this.catName} development area.`;

      console.log('text area value is : ', textArea.value);
      this.remark = textArea.value;
      console.log('default remark by teacher is : ', this.remark);
    } else if (this.score >= 67) {
      textArea.value = `${this.studentName.split(' ')[0]} is On Track in ${this.catName} development area.`;
      this.remark = textArea.value;
      console.log('default remark by teacher is : ', this.remark);
    }
  }

  goBack() {
    // window.history.back();
    // this.cat_id, this.class_id, this.student_id, this.dob_month, this.dob_date, this.dob_year
    console.log('cat name while returning to subcat: ', this.catName);
    const data = {
      dob_date: this.dob_date,
      dob_month: this.dob_month,
      dob_year: this.dob_year,
      teacherName: this.teacherName,
      studentName: this.studentName,
      standard: this.standard,
      division: this.division,
      totalStudents: this.totalStudents,
    };
    this.router.navigate(
      [
        '/sub-categories',
        this.class_id,
        this.student_id,
        this.catId,
        this.catName,
      ],
      { queryParams: data }
    );
  }
}
