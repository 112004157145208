import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment';

import { Router, RouterOutlet } from '@angular/router';
import { NavbarComponent } from './shared/component/navbar/navbar.component';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from './shared/component/spinner/spinner.component';
import { BehaviorSubject } from 'rxjs';
import { classRoom,school } from './shared/interfaces/common'
import { AlertComponent } from './shared/component/alert-box/alert-box.component';
import { MilestoneService } from './shared/services/milestone.service';
import { ToastComponent } from './shared/component/toast/toast.component';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule,
     NavbarComponent,ToastComponent,
     SpinnerComponent, AlertComponent
    ],
  providers: [MilestoneService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title: string = 'SAM';

  demoSend: string = "this is demo text";

  constructor(private router: Router,
    private _milestoneService: MilestoneService
  ) {
    console.log("Logs environment",environment.apiUrl); // Logs false for development environment
   }

  /**
   * This is used get the classroom data from one component to anoter component 
   * through out the application
   */
  private classRoom = new BehaviorSubject<classRoom>({
    class_id: 1,
    categoryId: 1,
    sub_id: 1,
    subcat_name: "sub_cat",
    student_id: 2,
  })
  setclassRoom(classObj: classRoom) {
    this.classRoom.next(classObj);
  }

  getClassRoom() {
    return this.classRoom.getValue();
  }

  /**
   * Private method to set the school data
   * @param schoolObj 
   */
  private school = new BehaviorSubject<school>({
    school_name: "School Name",
    school_id : 1,
    logo: "logo",
  });
  
  setSchool(schoolObj: school) {
    this.school.next(schoolObj);
  }

  getSchool() {
    return this.school.getValue();
  }




  // ngOnInit(){
  //   let user = localStorage.getItem('isLoggedIn');
  //   if(user === "true"){
  //      this.router.navigate(['/login']);
  //   }else{
  //     this.router.navigate(['/class-room']);
  //   }
  // }

}
