/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../shared/services/login.service';
import {AuthResponse} from '../../../shared/interfaces/api';
import { Store } from '@ngrx/store';
import { setTeacherObject } from '../../../../state/teacher/teacher.actions';
@Component({
  selector: 'app-loginpage',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  providers: [LoginService],
  templateUrl: './loginpage.component.html',
  styleUrl: './loginpage.component.css'
})
export class LoginpageComponent {

  isFormSubmitted = false;
  loginForm: FormGroup;
  isEmailValid: string | undefined;
  isPasswordValid: string | undefined;
  result: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _loginServices : LoginService,
    private store: Store
       ) { 
    // normally we can't add more than one validators to a single field, so we use formbuilder.
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(7)])
    }); 
  }


  validateForm() {

    this.isFormSubmitted = true;
    const loginInfo = this.loginForm.value;
    this._loginServices.AuthenticateUser(loginInfo['email'], loginInfo['password']).subscribe({ 
      next: (res : AuthResponse) => {
        console.log("res",res);
      if(res.data.userdata.token !== null && res.data.userdata.token !== undefined){
        console.log("login userData", res.data.userdata)
        this.store.dispatch(setTeacherObject({ teacherObject: res.data.userdata }));

        localStorage.setItem('token', res.data.userdata.token);
        localStorage.setItem('user', JSON.stringify(res.data.userdata));
        localStorage.setItem('isLoggedIn', "true");
        this.router.navigate(['/dashboard']);
       }
      },
      error: (error) => {
        this.result = error;
        console.log('inside error');
        alert('Invalid Credentials');
      }
    });
  }

  validateEmail(): void {
    const loginInfo = this.loginForm.value;
    // Regular expression for email validation
    const emailRegex: RegExp = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    // Test the email against the regex
    emailRegex.test(loginInfo['email']) ? this.isEmailValid = 'is-valid' : this.isEmailValid = 'is-invalid';

  }
  validatePassword(): void {
    const loginInfo = this.loginForm.value;

    loginInfo['password'].length > 7 ? this.isPasswordValid = 'is-valid' : this.isPasswordValid = 'is-invalid';

  }

  // toggle view password
  togglePassword() {
   const x = document.getElementById("userPassword") as HTMLInputElement;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

}
