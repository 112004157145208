import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { params } from '../../../../shared/interfaces/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarComponent } from '../../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../../shared/component/nameSection/namesection.component';
import { UserService } from '../../../../shared/services/user.service';
import { SpinnerComponent } from '../../../../shared/component/spinner/spinner.component';


// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

// imports for bargraph
import * as am5xy from '@amcharts/amcharts5/xy';
import { isEmpty } from 'rxjs';

// interface for category-wise results in summary report
interface Category {
  // for PREMIUM users only.
  cat_id: number;
  cat_name: string;
  chart_id: string;
  total: number;
  done: number;
  result: number;
}

@Component({
  selector: 'app-summary-report',
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    SpinnerComponent
    
  ],
  providers: [UserService],
  templateUrl: './summary-report.component.html',
  styleUrl: './summary-report.component.css',
})
export class SummaryReportComponent implements OnInit {
  navbarDisplay: string = 'none';
  params: any;
  studentName: string = 'studentName';
  born_date: number =0;
  born_month: number =0;
  born_year: number =0;
  age: string = '0';
  parent_first_name: string = 'firstName';
  parent_last_name: string = 'lastName';
  parent_email: string = 'email';
  parent_contact: number = 1;
  pieColor: number = 0x4fd6ff; // blue color default for basic users.
  user_id: number = 0;
  allData: any;
  values: any; // use this variable for premium users.
  user_plan: string = "user_plan"; 
  passing_obj: any;
  class_id: number = 0;
  basicResults: object = {};
  basicAverage: number = 0;
  basicTotal: number = 0;
  basicDone: number = 0;

  loader: boolean = false; // for spinner component

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {
    console.log('constructor called !!');

    this.class_id = Number(
      this.activatedRoute.snapshot.paramMap.get('class_id')
    )!;
    this.params = this.activatedRoute.snapshot.queryParams;
    console.log("printing params : ",this.params);

    this.born_date=this.params.child_born_date;
    this.born_month=this.params.child_born_month;
    this.born_year=this.params.child_born_year;
    this.age=this.calculateAge(this.born_year, this.born_month, this.born_date);
    this.studentName = this.params.child_name;
    this.parent_first_name = this.params.parent_name;
    this.parent_last_name = this.params.parent_last_name;
    this.parent_email = this.params.parent_email;
    this.parent_contact = this.params.parent_mobile;
    this.user_id = this.params.parent_id;

    console.log('this.user Id is : ', this.user_id);
  }

  // CategoryResult variable is for PREMIUM users only.
  CategoryResult: Category[] = [
    {
      cat_id: 15,
      cat_name: 'Physical Development',
      chart_id: 'chartdiv1',
      total: 0,
      done: 0,
      result: 0,
    },
    {
      cat_id: 16,
      cat_name: 'Communication and Language',
      chart_id: 'chartdiv2',
      total: 0,
      done: 0,
      result: 0,
    },
    {
      cat_id: 17,
      cat_name: 'Socio-Emotional Development',
      chart_id: 'chartdiv3',
      total: 0,
      done: 0,
      result: 0,
    },
    {
      cat_id: 18,
      cat_name: 'Problem Solving',
      chart_id: 'chartdiv4',
      total: 0,
      done: 0,
      result: 0,
    },
    {
      cat_id: 19,
      cat_name: 'General Knowledge',
      chart_id: 'chartdiv5',
      total: 0,
      done: 0,
      result: 0,
    },
    {
      cat_id: 20,
      cat_name: 'Arts and Creativity',
      chart_id: 'chartdiv6',
      total: 0,
      done: 0,
      result: 0,
    },
  ];

  ngOnInit() {
    console.log('ngOnInit called !!');

    this.userService.checkParentPlan(this.user_id).subscribe((wholeData=>{
      console.log("data recieved by checkParentPlan() is :", wholeData.data.data);
      if(JSON.stringify(wholeData.data.data)==="{}"){ // empty object recieved, hence  basic user.
        console.log("empty object ",wholeData.data.data);

        this.user_plan = 'basic';

      }else{  // non-empty object recieved , hence premium user.
        console.log("Non-empty object ",JSON.stringify(wholeData.data.data));
        
        this.user_plan = 'premium';
      }
    }))

  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit called !!');
    this.getSummaryReport();
    console.log('printing basicAverage in ngAfterOnInit : ', this.basicAverage);
    
  }

  determineColor(averageScore: number) {
    if (averageScore <= 20) {
      return am5.color('#E70B0B');
    } else if (averageScore >= 80) {
      return am5.color('#5A9D18');
    } else {
      return am5.color('#FBCD18');
    }
  }

  calculateAge(birthYear: number, birthMonth: number, birthDate: number) {
    const currentDate = new Date();
    const birthDateObj = new Date(birthYear, birthMonth - 1, birthDate);

    const yearsDiff = currentDate.getFullYear() - birthDateObj.getFullYear();
    const monthsDiff = currentDate.getMonth() - birthDateObj.getMonth();
    const daysDiff = currentDate.getDate() - birthDateObj.getDate();

    let age = '';
    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      age = `${yearsDiff - 1}`;
      if (monthsDiff < 0) {
        age += `.${12 + monthsDiff} `;
      } else {
        age += `.${monthsDiff}`;
      }
    } else {
      age = `${yearsDiff}`;
      if (monthsDiff > 0) {
        age += `.${monthsDiff}`;
      }
    }
    return age;
  }

  createChart(chartData: any) {
    // console.log("chartData", chartData);

    // Assign color to each data point
    let chartDataArray = Object.values(chartData).map((dataPoint: any) => {
      return {
        ...dataPoint,
        color: this.determineColor(dataPoint.averageScore),
      };
    });

    // console.log("chartDataArray", chartDataArray);

    // Clean up previous chart instance if exists
    // am5.array.each(am5.registry.rootElements, (root) => {
    //   if (root.dom.id === "chartdiv") {
    //     root.dispose();
    //   }
    // });

    const root = am5.Root.new('chartdiv');

    // Optional: Disable logo
    if (root._logo) {
      root._logo.dispose();
    }

    const charts = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );

    const cursor = charts.set('cursor', am5xy.XYCursor.new(root, {}));
    // cursor.lineY.set("visible", false);

    const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });

    const xAxis = charts.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: 'cat_name',
        renderer: xRenderer,
      })
    );

    xRenderer.labels.template.setAll({
      oversizedBehavior: 'truncate',
      textAlign: 'center',
    });

    xAxis.onPrivate('cellWidth', cellWidth => {
      xRenderer.labels.template.set('maxWidth', cellWidth);
    });

    const yRenderer = am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1 });

    const yAxis = charts.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: 100,
        renderer: yRenderer,
      })
    );

    const series = charts.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'averageScore',
        sequencedInterpolation: true,
        categoryXField: 'cat_name',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{cat_name} {valueY}',
        }),
      })
    );

    series.bullets.push(() => {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        sprite: am5.Circle.new(root, {
          radius: 15,
          stroke: am5.color('#000'),
          strokeWidth: 1,
          fill: am5.color('#fff'),
          centerX: am5.percent(50),
          centerY: am5.percent(50),
        }),
      });
    });

    series.bullets.push(() => {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1.0,
        stacked: 'up',
        sprite: am5.Label.new(root, {
          text: '{valueY}',
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          textAlign: 'center',
          populateText: true,
        }),
      });
    });

    series.columns.template.adapters.add('fill', (fill: any, target: any) => {
      const dataItem = target.dataItem?.dataContext as {
        color?: { _hex: string };
      };
      if (dataItem?.color && dataItem.color._hex) {
        const hexColor = Number(dataItem.color._hex);
        const red = (hexColor >> 16) & 255;
        const green = (hexColor >> 8) & 255;
        const blue = hexColor & 255;
        return am5.color(`rgb(${red}, ${green}, ${blue})`);
      }
      return undefined;
    });

    series.columns.template.setAll({
      cornerRadiusTL: 8,
      cornerRadiusTR: 8,
      cornerRadiusBR: 8,
      cornerRadiusBL: 8,
      strokeOpacity: 0,
      width: 18,
    });

    xAxis.data.setAll(chartDataArray);
    series.data.setAll(chartDataArray);

    series.appear(1000);
    // chart.appear(1000, 100);
  }

  createPieChart(chartId: string, result: number, color: number) {
    const root = am5.Root.new(chartId);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        // layout: root.verticalLayout,
      })
    );

    root.setThemes([am5themes_Animated.new(root)]);

    // pieChart data
    const data = [
      {
        status: 'Done',
        value: result,
        sliceSettings: {
          fill: am5.color(color), // score color
          stroke: am5.color(0x000000),
        },
      },
      {
        country: 'NotDone',
        value: 100 - result,
        sliceSettings: {
          fill: am5.color(0xffffff), // white
          stroke: am5.color(0x000000),
        },
      },
    ];

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'status',
      })
    );

    // adding colours
    series.slices.template.setAll({
      templateField: 'sliceSettings',
      showTooltipOn: 'click',
    });

    // hiding labels
    series.labels.template.setAll({
      maxWidth: 1,
      oversizedBehavior: 'hide',
    });
    // series.labels.template.setAll("hide");

    series.ticks.template.set('visible', false);

    // annimations
    series.animate({
      key: 'startAngle',
      to: result,
      duration: 3000,
      easing: am5.ease.yoyo(am5.ease.linear),
    });

    series.data.setAll(data);
    series.appear();
    chart.appear();
  }

  getSummaryReport() {
    // give this.user_id instead of hard coded value here.
    this.userService
      // user_id 2600 is a basic user for testing. 3399 also
      // user_id 2032 is a premium user who has not answered any qwestions.
      // user_id 2064 and 2065 are proper premium users.
      .getSummaryReportByUserId(this.user_id)
      .subscribe((data: any) => {

        console.log('whole data is : ', data);

        this.allData = Object.values(data.data);
        console.log("printing all data ",this.allData);

        console.log("basic value in summary report api : ",this.user_plan);
        // //checking if profile is basic or not
        // if(this.allData[0].cat_name === 'Basic Profile' && this.allData[0].averageScore!=0 && this.allData[0].result.length !=0 ) this.basic = true;

        if (this.user_plan=== "basic" ) {
          // ** BASIC users code .

          this.basicResults = this.allData[0].result;
          this.basicAverage = this.allData[0].averageScore;
          this.basicTotal = this.allData[0].total;
          this.basicDone = this.allData[0].done;

          console.log('printing basicResults variable : ', this.basicResults);
          console.log('basic average is : ', this.basicAverage);

          this.createPieChart('piechart', this.basicAverage, this.pieColor);
          console.log('printing after createPieChart function call');

        } 
          // *** PREMIUM USERS CODE

          if( this.user_plan==="premium" ){

            this.values = this.allData.filter(
              (obj: any) => obj.cat_name !== 'Basic Profile'
            ); // excluding the Basic Profile and only taking the Categories.
            console.log('filter output is : ', this.values);
  
            // creating bargraph
            this.createChart(this.values);
  
            // creating pie charts
            let i = 0;
            for (let obj of this.CategoryResult) {
              obj.result = this.values[i].averageScore;
              obj.total = this.values[i].total;
              obj.done = this.values[i].done;
  
              // assigning colors to piechart and category-card according to the score
              if (obj.result <= 25) {
                this.pieColor = 0xff5449; // red color
              } else if (obj.result >= 26 && obj.result <= 65) {
                this.pieColor = 0xffee52; // yellow color
              } else if (obj.result >= 66) {
                this.pieColor = 0xabff58; // green color
              }
              this.createPieChart(obj.chart_id, obj.result, this.pieColor);
              i++;
            }
          } 
          
      });
      this.loader=true;
  }

  goToSubcatSummary(cat_name: string, result: number) {

    // ** for PREMIUM
    if (result !== 0 && this.user_plan==="premium") {
      for (let i = 0; i < this.CategoryResult.length; i++) {
        if (cat_name === this.values[i].cat_name) {
          this.passing_obj = this.values[i];
          break;
        }
      }
      console.log('passing object is : ', this.passing_obj);
      window.sessionStorage.setItem(
        'passing_obj',
        JSON.stringify(this.passing_obj)
      );
      this.router.navigate(['/subcat-summary', this.class_id]);
    }

    // ** for BASIC
    if(this.user_plan==="basic" && this.basicAverage !==0 ){
      this.passing_obj=this.allData[0];
      console.log("printing passing_obj for basic profile : ",this. passing_obj);
      window.sessionStorage.setItem('passing_obj',JSON.stringify(this.passing_obj));
      this.router.navigate(['/subcat-summary', this.class_id]);
    }
  }
}
