import { createSelector, createFeatureSelector } from '@ngrx/store';
import { TeacherState } from '../../app/shared/interfaces/store';
// First, get the global object feature slice from the state
export const selectGlobalObjectState = createFeatureSelector<TeacherState>('teacherObject');

/** 
 * create a selector to get the global object
 * */
export const selectTeacherObject = createSelector(
  selectGlobalObjectState,
  (state: TeacherState) => state
);
