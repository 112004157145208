import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../../config/config';
import { AuthResponse } from '../interfaces/api';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url:string = 'http://localhost:8080';
  token :string | null = "";
  constructor(
    private http: HttpClient,
    private config: ApplicationConfig
  ) { 
    this.url =  config.getAPIEnvironment();
  }
  
  AuthenticateUser(email:string, password:string): Observable<AuthResponse> {
      if(window.localStorage){
       this.token =  localStorage.getItem('token');
      }
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.token
      });
  
      const data = {
        email: email,
        password: password,
      };
      return  this.http.post<AuthResponse>(`${this.url}/authenticate/login`, data, { headers });
    }
}
