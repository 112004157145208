import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../../config/config';

@Injectable({
  providedIn: 'root',
})
export class MilestoneService {
  url: string = '';
  token: string | null = '';

  constructor(
    private http: HttpClient,
    private config: ApplicationConfig
  ) {
    this.url = config.getAPIEnvironment();
  }

  getMilestoneBySubCategory(
    cat_id: number,
    subcat_id: number,
    class_id: number,
    student_id: number,
    dob_month: number,
    dob_day: number,
    dob_year: number
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    const data = {
      cat_id: cat_id,
      subcat_id: subcat_id,
      class_id: class_id,
      student_id: student_id,
      dob_month: dob_month,
      dob_day: dob_day,
      dob_year: dob_year,
    };

    return this.http.post<any>(
      `${this.url}/milestone/get_milestones_sub_category`,
      data,
      { headers }
    );
  }

  getMilestoneByCategory(
    cat_id: number,
    class_id: number,
    student_id: number,
    dob_month: number,
    dob_day: number,
    dob_year: number
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    const data = {
      cat_id: cat_id,
      class_id: class_id,
      student_id: student_id,
      dob_month: dob_month,
      dob_day: dob_day,
      dob_year: dob_year,
    };

    return this.http.post<any>(
      `${this.url}/milestone/get_milestones_category`,
      data,
      { headers }
    );
  }

  saveMilestone(
    cat_id: number,
    subcat_id: number,
    class_id: number,
    student_id: number,
    school_id: number,
    milestone_ids: string,
    result: number,
    remark: string,
    dob_month : string,
    dob_day :string,
    dob_year :string
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    const data = {
      cat_id: cat_id,
      subcat_id: subcat_id,
      class_id: class_id,
      student_id: student_id,
      school_id: school_id,
      milestone_ids: milestone_ids,
      result: result,
      remark: remark,
      dob_month : dob_month,
      dob_day :dob_day,
      dob_year :dob_year
    };

    return this.http.post<any>(`${this.url}/milestone/save_milestone`, data, {
      headers,
    });
  }

  getSavedMilestone(
    subcat_id: number,
    cat_id: number,
    class_id: number,
    student_id: number
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const reqHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    return this.http.get<any>(
      `${this.url}/milestone/getSavedMilestone/${subcat_id}/${cat_id}/${class_id}/${student_id}`,
      { headers: reqHeaders }
    );
  }

  updateSavedMilestone(
    newMilestone: string,
    new_score: number,
    record_id: number,
    remark: string
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const reqHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(
      `${this.url}/milestone/updateSavedMilestone/${newMilestone}/${new_score}/${remark}/${record_id}`,
      { headers: reqHeaders }
    );
  }

  // api's for saving photos

  // api for getting presigned url for each image
  getPresignedUrl(
    images: string[], // here images means fileNames of the images and not actual images
    catID: number,
    subCatID: number,
    studentID: number,
    dob_date: number,
    dob_month: number,
    dob_year: number
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const reqHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    const data = {
      images: images,
      catID: catID,
      subCatID: subCatID,
      studentID: studentID,
      dob_date: dob_date,
      dob_month: dob_month,
      dob_year: dob_year,
    };

    return this.http.post<any>(
      `${this.url}/image/presigned/`,
      data,
      { headers: reqHeaders }
    );
  }

  uploadImages(presignedUrl: string, image: any): Observable<object> {
    let file = new File([image], 'file1.jpeg');
    console.log('file', file);

    const reqHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'image/jpeg',
    });

    return this.http.put<any>(presignedUrl, file, { headers: reqHeaders });
  }

  insertImagePath(
    studentID: number,
    catID: number,
    subcatID: number,
    imagesPath: Array<string>,
    dob_date: number,
    dob_month: number,
    dob_year: number
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const reqHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    const data = {
      studentID: studentID,
      catID: catID,
      subcatID: subcatID,
      imagesPath: imagesPath,
      dob_date: dob_date,
      dob_month: dob_month,
      dob_year: dob_year
    };

    return this.http.post<any>(
      `${this.url}/image/insertImagePath/`,
      data,
      { headers: reqHeaders }
    );
  }

  fetchImages(
    childId: number,
    catId: number, 
    subcatId: number,
    dob_date: number,
    dob_month: number, 
    dob_year: number
  ):Observable<object>{
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const reqHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    return this.http.get<any>(
      `${this.url}/image/getSavedImages/${childId}/${catId}/${subcatId}/${dob_date}/${dob_month}/${dob_year}`,
      { headers: reqHeaders }
    );

  }
}
