import { Component, inject, TemplateRef } from '@angular/core';
import { NgbOffcanvas, OffcanvasDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Input } from '@angular/core';

@Component({
	selector: 'app-footer-navbar',
	standalone: true,
	imports: [],
	templateUrl: './footer-navbar.component.html',
	styleUrl: './footer-navbar.component.css'
})
export class FooterNavbarComponent {

	private offcanvasService = inject(NgbOffcanvas);
	closeResult = '';


	// temporarily giving hard-coded values
	userData: any;
	school_id: any;
	@Input() class_id: number = 0;
	teacherName: string = "TeacherName";



	constructor(private router: Router) {

	}

	ngOnInit() {
		if (typeof window !== 'undefined' && window.localStorage) {
			this.userData = window.localStorage.getItem('user');
			this.userData = JSON.parse(this.userData);
			this.school_id = this.userData.school_id;
			this.teacherName = this.userData.teacher_name;
			console.log("teacher id is : ", this.userData.teacher_id);
		}
	}

	goToDashboard() {
		this.router.navigate(['/dashboard']);
	}

	goToClassroom() {
		console.log("class id in footer navbar is ",this.class_id);
		console.log("teacher name in footer navbar is ",this.teacherName);
		this.router.navigate(['/class-room', this.class_id, this.teacherName]);
	}

	open(content: TemplateRef<any>) {
		this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvas-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	private getDismissReason(reason: any): string {
		switch (reason) {
			case OffcanvasDismissReasons.ESC:
				return 'by pressing ESC';
			case OffcanvasDismissReasons.BACKDROP_CLICK:
				return 'by clicking on the backdrop';
			default:
				return `with: ${reason}`;
		}
	}

	goToTeacherProfile() {
		this.offcanvasService.dismiss();
		this.router.navigate(['/teacherprofile']);
	}

	goToConnectWithParents() {
		this.router.navigate(['/connect-with-parents', this.class_id]);
	}

	logout() {
		console.log("logout pressed");
		this.offcanvasService.dismiss();
		localStorage.clear();
		this.router.navigate(['/login']);
	}
}
