<app-navbar [navbarDisplay] = "navbarDisplay"></app-navbar>

<div class="parent-container">
    <div class="header-container" [ngClass]="{'lightGreen' : averageResult>=66 , 'lightRed' : averageResult<=25 , 'lightYellow' : averageResult>=26 && averageResult<=65 , 'basicProfile' : catName==='Basic'}">
        <div class="heading">
            <h4 style="margin-left: 0.7rem;">{{catName}}</h4>
        </div>
        <div class="piechart">
            <div id="chartDiv"></div>
            <div class="score">
                <h6>Avg.</h6>
                <h4 style="margin: 0; color: #FF0000;">{{averageResult}}%</h4>
            </div>
        </div>
    </div>

    <div class="subcat-container dFlex " [ngClass]="{'lightGreen' : averageResult>=66 , 'lightRed' : averageResult<=25 , 'lightYellow' : averageResult>=26 && averageResult<=65 , 'basicProfile' : catName==='Basic' }">
        <h5 style="margin-top: 0.7rem;">Sub Categories</h5>
        <div class="catcard" *ngFor="let obj of subcat_results">
            <div class="upperCard">
                <p style="margin-left: 0.7rem; margin-top: 0.6rem;">{{obj['cat_name']}}</p>
                <p style="margin-right: 0.7rem; margin-top: 0.6rem;">{{obj['score']}}%</p>
            </div>
            <div class="lowerCard">
                <div class="progressBar" [style.width.%]="obj['score']" [ngClass]="{'Yellow': obj['score']>=26 && obj['score']<=65 , 'Red': obj['score'] <= 25 , 'Green': obj['score'] >= 66}"></div>
                <img *ngIf="obj['score'] >= 90" src="../../../../../assets/img/star.png" alt="">
            </div>
        </div>
    </div>

    <div class="parents-engagement dFlex" [ngClass]="{'lightGreen' : averageResult>=66 , 'lightRed' : averageResult<=25 , 'lightYellow' : averageResult>=26 && averageResult<=65 , 'basicProfile' : catName==='Basic'}">
        <h5 style="margin-top: 0.7rem;">Parents Engagement</h5>
            <!-- home-activities dropdown -->
        <div id="homeActivity" class="homeActivity">
            <div class="mainActivity" (click)="toggleExpand()">
                <p>Home Activities</p>
              <div class="activitynumbers" >
                <p>{{homeactivityDone}}/{{homeactivities}}</p>
                <img src="../../../../../assets/img/dropdown.png" alt="" [ngClass]="{'active': isActivityExpanded}">
              </div>
            </div>
    
            <div *ngIf="isActivityExpanded" class="expandabelDiv">
              <div *ngFor="let item of subcatSummary; let i = index" [ngClass]="'subCatexpand subCatexpand-' + i">
                <p>{{i + 1}}. {{item.subcat_name}}</p>
                <p>{{item.homeActivity.done}}/{{item.homeActivity.total}}</p>
              </div>
            </div>
        </div>

            <!-- worksheets dropdown -->
        <div id="workesheet" class="homeActivity">
          <div class="mainActivity" (click)="toggleWorksheetExpand()" >
            <p>Worksheets</p>
            <div class="activitynumbers">
              <p>{{worksheetDone}}/{{worksheet}}</p>
              <img src="../../../../../assets/img/dropdown.png" alt="" [ngClass]="{'active': isWorksheetExpanded}">
            </div>
  
          </div>
  
          <div *ngIf="isWorksheetExpanded" class="expandabelDiv">
            <div *ngFor="let item of subcatSummary; let i = index" [ngClass]="'subCatexpand subCatexpand-' + i">
              <p>{{i + 1}}. {{item.subcat_name}}</p>
              <p>{{item.workSheet.done}}/{{item.workSheet.total}}</p>
            </div>
          </div>
        </div>
 
    </div>
</div>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>